<template lang="pug">
v-main(fluid).pa-0
  v-footer(color="secondary" dark).py-6
    v-row(justify="center")
      v-col(cols="6" sm="3")
        v-img(src="cotai.png" loading="lazy" width="200px").mx-auto
      v-col(cols="6" sm="3")
        h3 Enlaces
        ul(style="list-style-type: none").ma-0.pa-0
          li: a(href="https://drive.google.com/open?id=1HwY38GiRBNfhaNJOyHpDnKi9KtWai5liOgXHYl229bs" target="_blank") Diccionario de Criterios
          li: a(href="https://cotai.org.mx/wp-content/uploads/2020/06/Dictamen-de-Mecanismos-de-Orientacio%CC%81n.docx") Mecanismos de Denuncia
          
      v-col(cols="6" sm="3")
        h3 Síguenos
        ul(style="list-style-type: none;").ma-0.pa-0
          li: a(href="https://www.facebook.com/COTAINL/" target="_blank") Facebook
          li: a(href="https://www.youtube.com/user/ctainl" target="_blank") Youtube
          li: a(href="https://twitter.com/COTAINL" target="_blank") Twitter
          li: a(href="https://www.instagram.com/cotainl/" target="_blank") Instagram
      v-col(cols="6" sm="3")
        h3 Contáctanos
        a(href="https://forms.gle/YBT1ugwrdfdxRRGt7") Sugerencias

</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>
</style>
