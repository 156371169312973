import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import vuetify from '@/plugins/vuetify';

export default{
	namespaced: true,
	state:{
		idmuni:{idmunicipios: 1},
	},

	mutations:{
		IDMUNI(state, value){
			state.idmuni = value
		},
	},

	actions:{

		addIdMuni({commit, dispatch}, idmunicipios){
			commit('IDMUNI',idmunicipios)
		},

	},

	getters:{
		getIdMuni(state){
		  return state.idmuni
		},
	}
}