
export const grafica_gastos = {
  type: 'line',
  data: {},
  options: {
    legend: {
      position: 'bottom',
      visible: false,
      labels: {
        fontSize: 12,
        padding: 12
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    lineTension: 1,
    tooltips: {
      xPadding: 20,
      yPadding: 10,
      titleFontSize: 20,
      bodyFontSize: 16,
      beginAtZero: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let datasetIndex = tooltipItem.datasetIndex;
          var value = data.datasets[datasetIndex].data[tooltipItem.index];
          if (parseInt(value) >= 1000) {
            value = '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            value = '$' + value;
          }
          return ' ' + value;
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          padding: 25,
          fontSize: 14,
          userCallback: function(value) {
              if (value > 1000000) {
                value = value / 1000000;
                value = value + 'M';
              }
              value = '$' + value;
              return value;
          }
        },
      }],
      xAxes: [{
        ticks: {
          fontSize: 12
        }
      }]
    }
  }
}
  
export default grafica_gastos;
