<template>
  <v-main class=" pa-0" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-text-field
          dense
          outlined
          name="name"
          label="Encuentra tu institución"
          id="id"
          v-model="search"
          append-icon="mdi-magnify"
          class="text-h6"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col  v-for="(item, i) in filterMunicipio" :key="i" cols="12" sm="6" md="4"  lg="3" class="my-3 pa-6 py-0">
        <v-card height="100%" class="tarjeta " outlined >
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar class="ma-3" size="90" tile>
              <v-img :src="`${$http.options.root}/fotos/${item.img}`" loading="lazy" contain></v-img>
            </v-avatar>

            <div>
              <v-card-title class="text-md-overline text-sm-h6 font-weight-black" style="word-break:normal;" align="right">
                {{ item.nommunicipio }}
              </v-card-title>
              <v-divider class="primary"></v-divider>
              <v-card-subtitle style="word-break:normal;" class=" text-sm-subtitle-2">
                <span class="font-weight-black "> Presupuesto:</span> ${{  item.presupuesto }} <br>
                <span class="font-weight-black "> Gastos     :</span> ${{ item.gastos }}
              </v-card-subtitle>
            </div>

          </div>
          <v-btn block text color="primary" @click="SaberMas(item)">Saber mas</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  var numeral = require('numeral');
  export default {
    data: () => ({

      municipios:[],
      search:''
    }),

    created(){
      this.init()
    },

    methods:{
      ...mapActions('idMunicipio',['addIdMuni']),
      init(){
        this.$http.get('municipios').then(response=>{
          this.municipios = response.body
          for(const i in this.municipios){
            this.municipios[i].presupuesto = numeral(this.municipios[i].presupuesto).format('0,0.00');
            this.municipios[i].gastos = numeral(this.municipios[i].gastos).format('0,0.00');
          }
        }).catch(error=>{
          console.lgo(error)
        })
      },

      SaberMas(item){
        if(this.$route.name == 'detalle.municipio'){
          this.addIdMuni(item)
        }else{
          this.addIdMuni(item)
          this.$router.push({name:'detalle.municipio', params:{ data: item } })
        }
      }
    },

    computed:{
      filterMunicipio: function(){
        var me = this
        if(this.municipios){
          return this.municipios.filter((mun)=>{
            var nom = mun.nommunicipio.toLowerCase();
            return nom.match(this.search.toLowerCase());
          })
        }
      },
    }
  }
</script>


<style scoped>

</style>
