<template lang="pug">
.home(fluid)
  banner
  Content
  v-row(justify="center")
    v-col(cols="11")
      h1.pl-5.mb-4.primary.white--text(style="box-shadow: 0px 4px #46755f;" id="instituciones").mt-3 Instituciones Públicas
      municipios
</template>

<script>
// @ is an alias to /src
import banner     from '@/components/banner.vue'
import municipios from '@/components/municipios.vue'
import Content from '@/components/Content.vue'

export default {
  name: 'Home',
  components: {
    banner,
    municipios,
    Content,
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>
