
export const gastoxcat = {
  type: 'doughnut',
  data: {},
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      visible: false,
      labels: {
        fontSize: 12,
        padding: 12
      },
    },
    responsive: true,
    tooltips: {
      xPadding: 20,
      yPadding: 10,
      titleFontSize: 20,
      bodyFontSize: 16,
      callbacks: {
        label: function(tooltipItem, data) {
          var value = data.datasets[0].data[tooltipItem.index];
          var porcentaje = value / data.total;
          porcentaje = parseInt(porcentaje * 100);
          // porcentaje = porcentaje.toFixed(2) * 100;
          porcentaje = porcentaje + '%';
          if (parseInt(value) >= 1000) {
            value = '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            value = '$' + value;
          }
          return ' ' + porcentaje + ' | ' + value;
        }
      }
    },
  }
}

export default gastoxcat;
