<template lang="pug">
v-col(cols="11")
  v-row(justify="center").justify-md-space-between
    v-col(cols="10" md="4")
      v-card(height="100%")
        v-card-title: h3 Presupuesto
        v-card-subtitle Es el dinero previsto que se destina para cubrir los costos de las acciones a realizar ante la contingencia sanitaria.
        v-card-title.justify-center: h2 {{ formatPrice(presupuesto) }}
    v-col(cols="10" md="4")
      v-card(height="100%")
        v-card-title: h3 Contrataciones
        v-card-subtitle Número total de procedimientos realizados para comprar, contratar y/o rentar algún bien, servicio u obra pública.
        v-card-title.justify-center: h2 {{ contrataciones }}
    v-col(cols="10" md="4")
      v-card(height="100%")
        v-card-title: h3 Pagos
        v-card-subtitle Dinero utilizado para pagar a los proveedores por las contrataciones y/o rentas de algún bien servicio u obra pública.
        v-card-title.justify-center: h2 {{ formatPrice(pagos) }}
</template>

<script>
import numeral from 'numeral'

export default {
  name: 'Totales',
  data() {
    return {
      presupuesto: 0,
      contrataciones: 0,
      pagos: 0,
    }
  },
  async mounted() {
    const { contrataciones, pagos, presupuesto } = await this.getValues()

    this.presupuesto = presupuesto
    this.contrataciones = contrataciones
    this.pagos = pagos
  },
  methods: {
    async getValues() {
      const { body } = await this.$http.get('home')

      return body[0]
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',');
      return `$ ${numeral(value).format('0,0.00')} MXN`
    },
  }
}
</script>

<style>

</style>
