<template>
  <v-main class="pa-0" fluid>
    <v-row class="">
      <v-col cols="12" class="py-0 pa-0 my-0"  width="100%" height="100%">
        <v-img src="banner4.png" loading="lazy" class="align-end">
          <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" class="text-center">
            <v-btn rounded color="primary" class="mt-8" dark @click="$vuetify.goTo('#instituciones')">
              Instituciones públicas 
            </v-btn>
          </v-col>
        </v-img>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
  export default {
    data: () => ({
     
    }),
  }
</script>


<style scoped>
</style>
