var numeral = require('numeral');

export default {
	methods: {

		traerFechaActual(){
			var f = new Date(); 
			return f.getFullYear() +'-'+ (f.getMonth() + 1 < 10? '0' + (f.getMonth() + 1): f.getMonth() + 1 ) +'-'+ (f.getDate()<10?'0'+f.getDate():f.getDate());
		},

		traerHoraActual(){
			var f = new Date(); 
			return (f.getHours()<10? '0'+f.getHours(): f.getHours()) + ':' + (f.getMinutes()<10? '0'+ f.getMinutes(): f.getMinutes())
		},

		traerMesActual(){
			var f = new Date();
			var primerDia = new Date(f.getFullYear(), f.getMonth(), 1).toISOString().substr(0, 10);
			var ultimoDia = new Date(f.getFullYear(), f.getMonth() + 1, 0).toISOString().substr(0, 10);
			const fecha = { fechaInicial: primerDia , fechaFinal: ultimoDia}
			return fecha;
		},
	
		consultaMunicipios(id_municipio){
			this.$http.get('municipios').then(response => {
				console.log('Municipios', response.body)
				this.municipios = response.body
			}).catch( error => {
				console.log('err municipios', error)
			})
		},
  
		FuenteDeIngresos(id_municipio){
			this.fuenteIngresosTotal = 0
			this.$http.get('fuentesdeingreso/'+ id_municipio).then(response =>{
        this.fuenteIngresos = response.body
        this.graficarFuentes(this.fuenteIngresos)
        for(const i in this.fuenteIngresos){
        	this.fuenteIngresosTotal += this.fuenteIngresos[i]["Fuentes de ingreso"]
          this.fuenteIngresos[i]["Fuentes de ingreso"] ='$ ' + numeral(this.fuenteIngresos[i]["Fuentes de ingreso"]).format('0,0.00');
        }
        this.fuenteIngresosTotal =  numeral(this.fuenteIngresosTotal).format('0,0.00');
        // console.log('fuente de ingresos', this.fuenteIngresos )
			}).catch(error =>{
				console.log('fuente de ingresos err', error)
			}).finally(()=>{

      })
    },
    
    PresupuestoXMunicipio(id_municipio){
			this.$http.get('presupuesto.municipio/'+ id_municipio).then(response =>{
        this.presupuestos = response.body
        // console.log('presupuesto', this.presupuestos )
			}).catch(error =>{
				console.log('presupuesto err', error)
			}).finally(()=>{
        
      })
    },

    AreaSolMunicipio(id_municipio){
    	this.areaSolMunicipioTotal = 0
			this.$http.get('area.sol.municipio/'+ id_municipio).then(response =>{
        this.areas = response.body
        // console.log('areas', this.areas )
        for(const i in this.areas){
        	this.areaSolMunicipioTotal += this.areas[i].Total
          this.areas[i].Total = '$ ' + numeral(this.areas[i].Total).format('0,0.00');
        }
        this.areaSolMunicipioTotal = numeral(this.areaSolMunicipioTotal).format('0,0.00');
			}).catch(error =>{
				console.log('areas err', error)
			}).finally(()=>{
        
      })
    },

    ProvedorxMunicipio(id_municipio){
			this.$http.get('proveedores.municipio/'+ id_municipio).then(response =>{
        this.provedores = response.body
        // console.log('provedores', this.provedores )
			}).catch(error =>{
				console.log('proveedores err', error)
			}).finally(()=>{
        
      })
    },

    ContratoxMunicipio(id_municipio){
    	this.contratosTotal = 0
			this.$http.get('contratos.municipio/'+ id_municipio).then(response =>{
        this.contratos = response.body
        for(const i in this.contratos){
        	this.contratosTotal += this.contratos[i].Total
          this.contratos[i].Total = '$ ' + numeral(this.contratos[i].Total).format('0,0.00');
        }
        this.contratosTotal = numeral(this.contratosTotal).format('0,0.00');
        // console.log('contratos', this.contratos )
			}).catch(error =>{
				console.log('contratos err', error)
			}).finally(()=>{
        
      })
    },
    
    ImplementacionxMunicipio(id_municipio){
    	this.implementacionesTotal = 0
			this.$http.get('implementacion.municipio/'+ id_municipio).then(response =>{
        this.implementacion = response.body
        for(const i in this.implementacion){
        	this.implementacionesTotal += this.implementacion[i].Total
          this.implementacion[i].Total = '$ ' + numeral(this.implementacion[i].Total).format('0,0.00');
        }
        this.implementacionesTotal = numeral(this.implementacionesTotal).format('0,0.00');

        // console.log('implementacion', this.implementacion )
			}).catch(error =>{
				console.log('implementacion err', error)
			}).finally(()=>{
        
      })
    },
    
    




	},


}
