<template lang="pug">
canvas(:id="[this.chartId]" height="300")
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'App',
  props: {
    chartData: Object,
    chartId: String
  },
  methods: {
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
    }
  },
  mounted() {
    this.createChart(this.chartId, this.chartData)
  }
};
</script>

<style lang="scss" scoped>

</style>
