import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007664', // CELESTE
        secondary: '#003a2e'
      },
    },
  },

  lang:{
      locales:{ es },
      current: 'es'
  }
});
