<template lang="pug">
.card
  img(src="./../assets/mascota_close.png" alt="mascota1")
  img(src="./../assets/mascota_open.png" class="img-top" alt="mascota2")
  span.bubble {{ dialog }}
</template>

<script>
export default {
  name: 'Mascota',
  props: {
    dialog: String
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: auto;
  height: 120px;
  position: relative;
  display: inline-block;

  &:hover {
    .img-top {
      display: inline;
    }

    .bubble {
      visibility: visible;
    }
  }

  .bubble {
    visibility: hidden;
    width: 140px;
    background-color: #1f665a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 90%;
    left: 50%;
    margin-left: -70px;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #215058 transparent;
    }
  }
}

img {
  top: 0;
  left: 0;
  z-index: 1;
  width: 70px;
}

.img-top {
  display: none;
  position: absolute;
}
</style>
