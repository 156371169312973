<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center" style="cursor: pointer;" @click="inicio">
        <v-img
          alt="App Logo"
          class="mr-4"
          contain
          src="tac_logo2.png"
          transition="scale-transition"
          width="60"
        />
        <!-- <h1 contain style="font-family: Avenir; cursor: pointer;" transition="scale-transition">tac</h1> -->
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items text-right>
       <v-btn small text :to="{ name: 'SobreTac' }">Sobre Tac</v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main >
      <router-view/>
    </v-main>
    <Footer/>

   
  </v-app>
</template>

<script>
import Footer  from '@/components/Footer.vue'
export default {
  name: 'App',

  components: {
    Footer,
  },

  data: () => ({
    //
  }),

  methods:{
    inicio(){
      this.$router.push({ name: 'Home' })
    }
  }
};
</script>

<style scoped>
main {
  background-image: linear-gradient(to bottom, #f6f6f6, #effff7);
}
</style>
