<template>
  <v-main  fluid>
    <v-row justify="center">
      <v-col cols="11" lg="6" class="text-center py-0 pa-0 font-weight-black text-h6 ">
        <div > PRESUPUESTO ASIGNADO A
          <span class="primary--text" style="font-size: 28px;"> {{ municipio.nommunicipio.toUpperCase() }} </span> 
        </div>
        <div class="green--text text-h6 text-sm-h4 font-weight-black">$ {{ municipio.presupuesto }} MX</div>
          <!-- <div>Gasto: {{ municipio.gastos }}</div> -->
      </v-col>  
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="chartdivv" ref="chartdivv" class="pa-3"></div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0">
          <v-row justify="center">
            <!-- DESGLOCE DE PRESUPUESTOS  -->
            <v-col cols="12" lg="11"  class="pa-6 " >
              <span class="text-h6">DESGLOSE DE PRESUPUESTO </span>
              <v-card  outlined >
                <v-card-actions clas="py-0 my-0">
                  <v-text-field
                    outlined
                    dense
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar "
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-card-actions>
                
                <v-data-table
                  :headers="headers"
                  :items="presupuestos"
                  :search="search"
                  fixed-header
                  height="250px"
                  loading-text="Cargando... Por favor espere."
                  hide-default-footer
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  @page-count="pageCount = $event"
                  dense
                >
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions >
                  <v-spacer></v-spacer>
                 <span class="font-weight-bold text-h6">TOTAL: $ {{ municipio.presupuesto}} </span>
                </v-card-actions>
                  <!-- PAGINACION -->
                <div class="text-center pt-2">
                  <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center">
            <!-- AREAS SOLICITADAS -->
            <v-col cols="12" sm="8" class="pa-6 " >
              <div class="text-center py-0 pa-0 font-weight-black text-h6 ">TIPO DE CONTRATACIÓN</div>
              <canvas id="contractTypesChart" height="100"></canvas>
            </v-col>
          </v-row>

          <v-card height="2px" class="primary" width="100%"></v-card>

          <!-- IMPLEMENTACION -->
          <v-row justify="center">
            <v-col cols="11"  class="pa-6 " >
              <div class="py-0 pa-0 font-weight-black text-h6 ">DESTINO DE LOS RECURSOS</div>

              <v-card  outlined >
                <v-card-actions clas="py-0 my-0">
                  <v-text-field
                    v-model="search6"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar "
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-card-actions>
                
                <v-data-table
                  :headers="himplementacion"
                  :items="implementacion"
                  :search="search6"
                  fixed-header
                  height="350px"
                  loading-text="Cargando... Por favor espere."
                  hide-default-footer
                  :page.sync="page6"
                  :items-per-page="itemsPerPage6"
                  @page-count="pageCount6 = $event"
                  dense
                >
                </v-data-table>
                <v-card-actions >
                  <v-spacer></v-spacer>
                </v-card-actions>
               
                  <!-- PAGINACION -->
                <div class="text-center pt-2">
                  <v-pagination v-model="page6" :length="pageCount6"></v-pagination>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card height="2px" class="primary" width="100%"></v-card>
          

          <v-row justify="center">
            <v-col cols="8" class="pa-6 " >
              <div class="text-center py-0 pa-0 font-weight-black text-h6 ">GASTO DE LA INSTITUCIÓN</div>
              <div cols="12" id="spendingsChart"></div>
              <div cols="12" class="text-right">
                <span align="right" class="font-weight-bold text-h6">TOTAL: $ {{ municipio.gastos }} </span>
              </div>
            </v-col>

          </v-row>

          <v-row justify="center">
            <v-col cols="6" lg="4" align="center">

              <v-card height="250" width="220px" class="transparent elevation-0" :href="completeLinkDownload" target="_blank">
                <v-img src="descarga1.jpg" style="height: 250px;" contain/>
              </v-card>
            </v-col>

            <v-col cols="6" lg="4" align="center">
              <v-card height="250" width="220px" class="transparent elevation-0" :href="relevantLinkDownload" target="_blank">
                <v-img src="descarga2.jpg" style="height: 250px;" contain/>
              </v-card>
            </v-col>          
          </v-row>

          <v-row justify="center">
            <v-col cols="6">
              <v-card>
                <v-card-title>
                  Resumen de datos
                </v-card-title>
                <v-card-text>
                  <h2>{{ municipio.nommunicipio }}</h2>
                  <div class="contact-section">
                    <h3>Periodo reportado</h3>
                    <h4>{{ municipio.inicio }} a {{ municipio.final }}</h4>
                  </div>
                  <v-divider light />
                  <div class="contact-section">
                    <h3>Contacto</h3>
                    <h4>Teléfono:</h4>
                    <p>{{ municipio.telefono }}</p>
                    <h4>Correo:</h4>
                    <p>{{ municipio.correo }}</p>
                    <h4>Sitio web covid:</h4>
                    <a :href="municipio.web" target="_blank" rel="noreferrer">{{ municipio.web }}</a>
                  </div>
                  <v-divider light />
                  <div class="contact-section">
                    <h3>Última fecha de actualización</h3>
                    <h4>{{ lastUpdatedAt }}</h4>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="11">
        <municipios/>
      </v-col>
    </v-row>
      
  </v-main>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import  Mixins from '@/mixins/mixins.js';
  import piePagina  from '@/components/piePagina.vue'
  import municipios from '@/components/municipios.vue'
  // Importando componentes de la grafica
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  import Chart from 'chart.js'

  var numeral = require('numeral');


  export default {
    mixins:[Mixins],
    components: {
      piePagina,
      municipios
    },
    data:()=>({
      // Totales
      fuenteIngresosTotal:0,
      areaSolMunicipioTotal:0,
      contratosTotal:0,
      implementacionesTotal:0,

      municipio:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      search: '',
      headers: [
        { text: 'Denominacion ',value: 'Denominacion_de_la_partida' },
        { text: 'Monto', value: 'Presupuesto', align:'right' },
      ],
      page2: 1,
      pageCount2: 0,
      itemsPerPage2: 20,
      search2: '',
      hfuenteIngresos: [
        { text: 'Fuente de financiamiento',value: 'Fuente_de_financiamiento' },
        { text: 'Monto', value: 'Fuentes de ingreso', align:'right' },
      ],
      page3: 1,
      pageCount3: 0,
      itemsPerPage3: 20,
      search3: '',
      hareas: [
        { text: 'Área solicitante ',value: 'Area_solicitante' },
        { text: 'Total', value: 'Total', align:'right' },
      ],

      page4: 1,
      pageCount4: 0,
      itemsPerPage4: 20,
      search4: '',
      hprovedores: [
        { text: 'Razon social del proveedor ',value: 'Razon_social_del_proveedor' },
      ],

      page6: 1,
      pageCount6: 0,
      itemsPerPage6: 20,
      search6: '',
      himplementacion: [
        { text: 'Descripcion de las obras, bienes o servicios a contratar', value: 'Descripcion_de_las_obras_bienes_o_servicios_a_contratar', width: 50 },
        { text: 'Destino de la obra, bien o servicio', value: 'Descripcion_del_destino_de_las_obras_bienes_contratados', width: 350 }
      ],

      presupuestos:[], //* PRESUPUESTO POR MUNICIPIO
      fuenteIngresos:[],
      areas         :[],
      provedores    :[],
      contratos     :[],
      implementacion:[],
      contractTypes: [],
      spendingsChartData: [],
      contractTypesChart: null
    }),
    computed: {
      ...mapGetters('idMunicipio',['getIdMuni']),
      relevantLinkDownload() {
        return `${this.$http.options.root}datafiles/${this.municipio.linkrelevante}`
      },
      completeLinkDownload() {
        return `${this.$http.options.root}datafiles/${this.municipio.linkcompleto}`
      },
      lastUpdatedAt() {
        return (new Date(this.municipio.actualizacion)).toLocaleDateString()
      }
    },

    watch:{
      getIdMuni(){
        console.log(this.getIdMuni)
        this.init()
      }
    },

    created(){
      this.init()
    },

    mounted() {
      this.graficar()
    },

    methods:{
      async init(){
        this.municipio =  this.getIdMuni
        window.scrollTo( 0, 0);
        if(this.contractTypesChart) {
          this.contractTypesChart.destroy()
        }

        this.consultaDatosGrafica();
        this.getContractTypesCountByInstitution()

        this.FuenteDeIngresos(this.municipio.idmunicipios)
        this.AreaSolMunicipio(this.municipio.idmunicipios)
        this.ProvedorxMunicipio(this.municipio.idmunicipios)
        this.ContratoxMunicipio(this.municipio.idmunicipios)
        this.ImplementacionxMunicipio(this.municipio.idmunicipios)
        await this.getSpendingsChartData()
        this.drawSpendingsChart(this.municipio.idmunicipios)
      },
      async getSpendingsChartData() {
        let { body: graphData } = await this.$http.get('pagos.municipios')

        this.spendingsChartData = graphData.filter(entry => entry.idmunicipio === this.municipio.idmunicipios)
      },
      drawSpendingsChart(id) {
        let colors = ['#2f4f4f', '#2e8b57', '#800000', '#9acd32', '#00008b', '#ff0000', '#ff8c00', '#ffd700', '#00ff00', '#00fa9a', '#4169e1', '#000000', '#00bfff', '#0000ff', '#d8bfd8', '#ff00ff', '#fa8072', '#808000', '#ff1493', '#ee82ee'];
        let spendings = []
        let uniquePaymentDates = Array.from(new Set(this.spendingsChartData.map(payment => payment.fecha)))
        let uniquePaymentMonths = Array.from(new Set(uniquePaymentDates.map(date => date.slice(3)))).sort((first, next) => {
          if (new Date(parseInt(first.slice(3)), parseInt(first.slice(0, 2)) - 1) > new Date(parseInt(next.slice(3)), parseInt(next.slice(0, 2)) - 1)) {
            return 1
          }

          return -1
        })
        let height = 400

        let valuesArray = []

        uniquePaymentMonths.forEach(paymentMonth => {
          valuesArray.push(this.spendingsChartData.filter(payment => payment.fecha.slice(3) === paymentMonth).reduce((acc, payment) => acc + payment.Monto_de_pago, 0))
        })

        spendings.push({
          label: this.municipio.nommunicipio,
          data: valuesArray,
          backgroundColor: colors[0],
          borderColor: colors[0],
          fill: false,
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 7
        })

        if (screen.width < 680) {
          height = 500
        } if (screen.width < 515) {
          height = 550
        }

        document.getElementById('spendingsChart').innerHTML = ""
        document.getElementById('spendingsChart').innerHTML = `<canvas id='spendingsChartIdentifier' height='${height}' />`
        const container = document.getElementById('spendingsChartIdentifier')

        const chart = new Chart(container, {
          type: 'line',
          data: {
            labels: uniquePaymentMonths,
            datasets: spendings
          },
          options: {
            onResize(ch, ns) {
              if (screen.width < 680) {
                ch.height = 500
              }
              else if (screen.width < 515) {
                ch.height = 550
              }
            },
            legend: {
              position: 'bottom',
              visible: false,
              labels: {
                fontSize: 12,
                padding: 12
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            lineTension: 1,
            tooltips: {
              xPadding: 20,
              yPadding: 10,
              titleFontSize: 20,
              bodyFontSize: 16,
              beginAtZero: true,
              callbacks: {
                label: function(tooltipItem, data) {
                  let datasetIndex = tooltipItem.datasetIndex;
                  var value = data.datasets[datasetIndex].data[tooltipItem.index];
                  if (parseInt(value) >= 1000) {
                    value = '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    value = '$' + value;
                  }
                  return ' ' + value;
                }
              }
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                  fontSize: 14,
                  userCallback: function(value) {
                    if (value > 1000000) {
                      value = value / 1000000;
                      value = value + 'M';
                    }
                    value = '$' + value;
                    return value;
                  }
                },
              }],
              xAxes: [{
                ticks: {
                  fontSize: 12
                }
              }]
            }
          }
        })
    },

    consultaDatosGrafica(){
        this.$http.get('presupuesto.municipio/'+ this.municipio.idmunicipios).then(response =>{
          this.presupuestos = response.body
          this.graficar(this.presupuestos)
          for(const i in this.presupuestos){
            this.presupuestos[i].Presupuesto = '$ ' + numeral(this.presupuestos[i].Presupuesto).format('0,0.00');
          }
          // console.log('presupuesto', this.presupuestos )
        }).catch(error =>{
          console.log('presupuesto err', error)
        }).finally(()=>{

        })
      },

      async getContractTypesCountByInstitution() {
        let { body: data } = await this.$http.get('contratacion.municipios')

        this.contractTypes = data.filter(entry => {
          return entry.institution_id === this.municipio.idmunicipios
        })

        this.drawContractTypesGraph()
      },

      consultaDatosTabla(){
        this.$http.get('datos.tabla/'+ this.municipio.id).then( response =>{
         this.grafica = response.body;
        }).catch( error =>{
         console.log('error', error)
        }).finally( ()=>{
         this.loading  = false;
        })
      },

      drawContractTypesGraph() {
        let labels = this.contractTypes.map(entry => {
          return entry.tipo_de_procedimiento_de_contratacion
        })
        let data = this.contractTypes.map(entry => {
          return entry.cantidad
        })

        const container = document.getElementById('contractTypesChart')
        const chart = new Chart(container, {
          type: 'horizontalBar',
          data: {
            labels,
            datasets: [{
              label: 'Tipos de contrataciones',
              data,
              backgroundColor: [
                '#007664',
                '#245b7b',
                '#247b4b',
                '#0a3a2e'
              ],
              borderWidth: 1
            }]
          },
          options: {
            indexAxis: 'y'
          }
        })

        this.contractTypesChart = chart
      },

      graficar(datosGraf){
        console.log(this.$refs)
        let chart = am4core.create(this.$refs.chartdivv, am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.data = datosGraf;

        chart.innerRadius = am4core.percent(40);
        chart.depth = 60;
        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.calculatePercent = true;

        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries.labels.template.radius = am4core.percent(-40);
        pieSeries.labels.template.fill = am4core.color("white");

        pieSeries.dataFields.value = "Presupuesto";
        pieSeries.dataFields.depthValue = "Presupuesto";
        pieSeries.dataFields.category = "Denominacion_de_la_partida";
        pieSeries.slices.template.cornerRadius = 5;
        pieSeries.colors.step = 3;

        // Disable ticks and labels
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Disable tooltips
        pieSeries.slices.template.tooltipText = "";

        // Add a legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "buttom";
        // chart.legend.valueLabels.template.text = `: $ {depthValue}`;
      },

      graficarFuentes(datosGraf){
        let chart = am4core.create(this.$refs.chartFuentes, am4charts.XYChart)
        chart.data = datosGraf
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.dataFields.category = 'Fuente_de_financiamiento'
        categoryAxis.renderer.labels.template.wrap = true
        categoryAxis.renderer.labels.template.maxWidth = 100

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.min = 0
        valueAxis.extraMax = 0.1

        let series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.categoryX = "Fuente_de_financiamiento"
        series.dataFields.valueY = "Fuentes de ingreso"
        series.columns.template.adapter.add("fill", function (fill, target) {
          return chart.colors.getIndex(target.dataItem.index);
        });

        let labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('$#,###.00')}";
        labelBullet.label.dy = -10
      }
    }
  }
</script>

<style scoped>
#chartdivv {
  width: 100%;
  height: 500px;
}

#chartFuentes {
  width: 100%;
  height: 350px;
}

.contact-section {
  margin: 1rem 0;
}
</style>

