<template lang="pug">
v-main.pa-0
  v-row
    v-col(cols="11")
      h2 Cantidad por categoría
    v-col(cols="8" md="4")
      v-card.mx-auto(color="#ffdab9")
        v-card-text Conoce la naturaleza de las contrataciones que llevan a cabo los sujetos obligados para hacer frente a las contrataciones
    v-col(cols="4")
      Mascotac(:dialog="'Presiona el botón de filtro para seleccionar todo!'")
  v-row(justify="center")
    v-col(cols="12")
      FiltroInstitucion(:municipios='municipios' :selected='selected' @update-selected="updateSelected" @clear-filter="clearFilter")
  v-row(justify="center")
    v-col(cols="12")
      div(id="materiasGraphContainer")
</template>

<script>
import removeAccents from 'remove-accents'
import Chart from 'chart.js'

import Mascotac from "@/components/Mascotac"
import FiltroInstitucion from "./FiltroInstitucion"
import grafica_gastoxcat from "./../assets/charts/gastoxcat.js";

export default {
  name: 'GastoPorInstitucion',
  components: {
    FiltroInstitucion,
    Mascotac,
  },
  data() {
    return {
      grafica_gastoxcat: grafica_gastoxcat,
      municipios: [],
      labels: [],
      selected: null,
      gastosxcat: {},
      componentKey: 0,
      graphData: []
    }
  },
  async created() {
    let { data: institutions } = await this.$http.get('municipios.all')
    let { data: graphData } = await this.$http.get('materias.municipios')

    this.municipios = institutions.map(entry => {
      return entry.nommunicipio
    })

    this.graphData = graphData

    this.drawGraph()
  },
  methods: {
    clearFilter() {
      this.selected = null
      this.drawGraph()
    },
    updateSelected(newValue) {
      this.selected = newValue === undefined ? null : newValue
      this.drawGraph()
    },
    drawGraph() {
      let data
      let labels = Array.from(new Set(this.graphData.map(entry => {
        return removeAccents(entry.materia.toUpperCase())
      })))

      if (this.selected !== null) {
        let entries = this.graphData.filter(entry => {
          return entry.institution_name === this.municipios[this.selected]
        })

        data = labels.map(label => {
          let entriesByLabel = entries.filter(entry => {
            return removeAccents(entry.materia.toUpperCase()) === label
          })

          return entriesByLabel.reduce((acc, entry) => {
            return acc + entry.cantidad
          }, 0)
        })
      } else {
        data = labels.map(label => {
          let entriesByLabel = this.graphData.filter(entry => {
            return removeAccents(entry.materia.toUpperCase()) === label
          })

          return entriesByLabel.reduce((acc, entry) => {
            return acc + entry.cantidad
          }, 0)
        })
      }

      document.getElementById('materiasGraphContainer').innerHTML = ""
      document.getElementById('materiasGraphContainer').innerHTML = "<canvas id='materiasGraph' height='300' />"
      const container = document.getElementById('materiasGraph')

      const chart = new Chart(container, {
        type: 'horizontalBar',
        data: {
          labels,
          datasets: [{
            label: 'Materia',
            data,
            backgroundColor: [
              '#007664',
              '#245b7b',
              '#247b4b',
              '#0a3a2e'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
