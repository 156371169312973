<template lang="pug">
v-main(fluid).pa-0.ma-0
  v-row(justify="center").ma-0
    v-col(cols="11" sm="10").text-center.pt-8
      h1 Descubre los datos abiertos, accesibles y oportunos
      p.ma-0(style="padding: 0 5vw;") sobre las etapas de planeación, licitación, adjudicación, contratación e implementación de las contrataciones de las instituciones públicas del estado de Nuevo León que realizan esfuerzos para mitigar la contingencia sanitaria.

    Totales

    v-col(cols="11" id='cuanto')
      h1.pl-5.mb-2.primary.white--text(style="box-shadow: 0px 4px #46755f;") ¿Cuánto $ se invierte?
      GastoPorInstitucion
      GastoPorHabitante

    v-col(cols="11" id='donde')
      h1.pl-5.mb-2.primary.white--text(style="box-shadow: 0px 4px #46755f;") Naturaleza de la contratación
      GastoPorCategoria

    //- No jala bien todavia
    //- v-col(cols="10" id='quien')
    //-   h1.content-title ¿A quién va el $?
    //-   GastoPorProveedor
</template>

<script>
import GastoPorHabitante from "./GastoPorHabitante"
import GastoPorInstitucion from "./GastoPorInstitucion"
import GastoPorCategoria from "./GastoPorCategoria"
import GastoPorProveedor from "./GastoPorProveedor"
import Totales from "./Totales"

export default {
  name: 'Content',
  components: {
    GastoPorHabitante,
    GastoPorInstitucion,
    GastoPorCategoria,
    GastoPorProveedor,
    Totales,
  },
}
</script>

<style lang="scss">
// .content-title {
//   color: #62938a;
//   padding: 0;
//   margin: 0;
// }
h1 {
  margin: 0;
  padding: 0;
}
</style>
