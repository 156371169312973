<template>
  <v-main class=" pa-0 mt-10" fluid>
    <v-footer  color="secondary" dark>
      <div class="d-flex flex-no-wrap justify-space-between">
        <v-img src="sofsol.png" loading="lazy" contain width="180"></v-img>
        <div>
          <v-card-title class="overline font-weight-black" style="word-break:normal;" align="right">
          </v-card-title>
        </div>
      </div>
    </v-footer>
  </v-main>
</template>

<script>
  export default {
    data: () => ({
     
    }),
  }
</script>


<style scoped>
</style>
