<template lang="pug">
v-main(fluid).pa-0
  v-card.mx-auto
    v-toolbar(flat color="primary" dark)
      v-btn(icon @click="$emit('clear-filter')")
        v-icon mdi-filter
      v-toolbar-title Filtra instituciones
    
    v-card-text
      v-chip-group(v-model="selectedValue" column)
        v-chip(v-for="item in municipios" filter outlined) {{ item }}
</template>

<script>
export default {
  name: 'FiltroInstitucion',
  props: {
    municipios: Array,
    selected: Number,
  },
  emits: [
    'update-selected',
  ],
  computed: {
    selectedValue: {
      get: function() {
        return this.selected
      },
      set: function(newValue) {
        this.$emit('update-selected', newValue);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
