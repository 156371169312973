<template lang="pug">
v-main.pa-0
  v-row(justify="center")
    v-col(cols="11")
      h2 Gastos por proveedor
    v-col(cols="11")
      PackedCircle
</template>

<script>
import Chart from "./Chart";
import PackedCircle from "./PackedCircle"

export default {
  name: 'GastoPorInstitucion',
  components: { Chart, PackedCircle },
  data() {
    return {
      proveedores: []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
