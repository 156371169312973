<template lang="pug">
v-dialog(v-model="active" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-img(
      src="mascotac.png"
      width="80px"
      v-bind="attrs"
      v-on="on"
      style="cursor: pointer;"
    )
  v-card
    v-card-title.headline.grey.lighten-2 Tip de Lupita
    v-card-text.pt-4 {{ dialog }}
</template>

<script>
export default {
  name: 'Mascotac',
  props: {
    dialog: String
  },
  data() {
    return {
      active: false
    }
  }
}
</script>
