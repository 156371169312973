import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import LoadScript from 'vue-plugin-load-script';
 
Vue.use(LoadScript);

import VueResource from 'vue-resource'


Vue.config.productionTip = false
Vue.use(VueResource)


// Vue.http.options.root = 'https://petrotacnum.com:3000/'
// Vue.http.options.root = 'http://localhost:3000/'
Vue.http.options.root = 'https://tacnl.org.mx/api/'
// Vue.http.options.root = 'http://159.65.182.193:3000/'


// console.log(Vue.http.options.root)


// Vue.http.interceptors.push((request, next) => {
//    request.headers.set('Authorization', 'Bearer '+ localStorage.tlaKey)
//    request.headers.set('Accept', 'application/json')
//    next()
// });


new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
