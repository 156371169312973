import Vue from 'vue'
import VueRouter    from 'vue-router'
import Home         from '../views/Home.vue'
import DetMunicipio from '../views/detMunicipio.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/detalle.municipio',
    name: 'detalle.municipio',
    component: DetMunicipio
  },
  {
    path: '/sobre-tac',
    name: 'SobreTac',
    component: () => import('../views/SobreTac.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  return next();
})

export default router
