<template lang="pug">
v-main(fluid).pa-0
  v-row()
    v-col(cols="11").pb-0
      h2.pa-0.ma-0 Gasto por institución
    v-col(cols="8" md="4")
      v-card.mx-auto(color="#ffdab9")
        v-card-text Gastos totales de las instituciones para efectos del COVID-19 en los últimos 12 meses.
    v-col(cols="4")
      Mascotac(:dialog="'Presiona los puntos de la gráfica para ver la información con más detalle!'")
    v-col(cols="12").chart-container
      div(id="institutionSpendingsChartContainer")
</template>

<script>
import Chart from 'chart.js'
import Mascotac from "@/components/Mascotac"
import grafica_gastos from "./../assets/charts/gastos.js";

export default {
  name: 'GastoPorInstitucion',
  components: { Mascotac },
  data() {
    return {
      institutions: [],
      grafica_gastos: grafica_gastos,
      graphData: []
    }
  },
  methods: {
    drawGraph() {
      let colors = ['#2f4f4f', '#2e8b57', '#800000', '#9acd32', '#00008b', '#ff0000', '#ff8c00', '#ffd700', '#00ff00', '#00fa9a', '#4169e1', '#000000', '#00bfff', '#0000ff', '#d8bfd8', '#ff00ff', '#fa8072', '#808000', '#ff1493', '#ee82ee'];
      let spendings = []
      let uniquePaymentDates = Array.from(new Set(this.graphData.map(payment => payment.fecha)))
      let uniquePaymentMonths = Array.from(new Set(uniquePaymentDates.map(date => date.slice(3))))
      let height = 400

      this.institutions.forEach((institution, index) => {
        let institutionPayments = this.graphData.filter(payment => payment.idmunicipio === institution.id)
        let valuesArray = []

        uniquePaymentMonths.forEach(paymentMonth => {
          valuesArray.push(institutionPayments.filter(payment => payment.fecha.slice(3) === paymentMonth).reduce((acc, payment) => acc + payment.Monto_de_pago, 0))
        })

        spendings.push({
          label: institution.name,
          data: valuesArray,
          backgroundColor: colors[index],
          borderColor: colors[index],
          fill: false,
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 7
        })
      })

      if (screen.width < 680) {
        height = 500
      } if (screen.width < 515) {
        height = 550
      }

      document.getElementById('institutionSpendingsChartContainer').innerHTML = ""
      document.getElementById('institutionSpendingsChartContainer').innerHTML = `<canvas id='institutionSpendingsChart' height='${height}' />`
      const container = document.getElementById('institutionSpendingsChart')


      console.log(uniquePaymentMonths)
      console.log(spendings)
      const chart = new Chart(container, {
        type: 'line',
        data: {
          labels: uniquePaymentMonths,
          datasets: spendings
        },
        options: {
          onResize(ch, ns) {
            if (screen.width < 680) {
              ch.height = 500
            }
            else if (screen.width < 515) {
              ch.height = 550
            }
          },
          legend: {
            position: 'bottom',
            visible: false,
            labels: {
              fontSize: 12,
              padding: 12
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          tooltips: {
            xPadding: 20,
            yPadding: 10,
            titleFontSize: 20,
            bodyFontSize: 16,
            beginAtZero: true,
            callbacks: {
              label: function(tooltipItem, data) {
                let datasetIndex = tooltipItem.datasetIndex;
                var value = data.datasets[datasetIndex].data[tooltipItem.index];
                if (parseInt(value) >= 1000) {
                  value = '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  value = '$' + value;
                }
                return ' ' + value;
              }
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                padding: 25,
                fontSize: 14,
                userCallback: function(value) {
                  if (value > 1000000) {
                    value = value / 1000000;
                    value = value + 'M';
                  }
                  value = '$' + value;
                  return value;
                }
              },
            }],
            xAxes: [{
              ticks: {
                fontSize: 12
              }
            }]
          }
        }
      })
    }
  },
  async created() {
    let { data: institutions } = await this.$http.get('municipios.all')
    let { body: graphData } = await this.$http.get('pagos.municipios')

    this.graphData = graphData
    this.institutions = institutions.map(institution => ({ name: institution.nommunicipio, id: institution.idmunicipios }))

    this.drawGraph()
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
}
</style>
